// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

$white: #fff;
$black: #000;
$green: green;
$link-water: #f2f5fb;
$purple-heart: #6a26da;
$blue-ribbon: #2b59ff;
$blue-ribbon-2: #0041ff;
$green-2: #22cc74;
$tree-poppy: #ff971d;
$ebony-clay: #25373f;
$golden-tainoi: #ffd166;
$coral: #ff8d52;
$anakiwaap: #a9d2ff;
$silver: #adadad;
$java: #15cda8;
$java-2: #1de2cf;
$ice-cold: #96f7d2;
$radical-red: #ff4057;
$electric-violet: #5338fc;
$electric-violet-2: #5034fc;
$electric-violet-3: #6001d3;
$electric-violet-4: #4f34fc;
$oxford-blue: #3b4656;
$white-lilac: #f8f9fc;
$victoria: #494ca2;
$dodger-blue: #0f89ff;
$royal-blue: #3e49f3;
$blue: #7984ee;
$caribbean-green: #0cd68a;
$gorse: #fee856;
$yellow: #fcdc00;
$yellow-2: #ffce3e;
$yellow-3: #ffd700;
$shark: #262729;
$athens-gray: #f3f4f6;
$primary: #ff5722;
$readical-red: #fd346e;
$flamingo: #ef4339;
$egg-blue: #0abfbc;
$jumbo: #828288;
$orange: #ff5200;
$torch-red: #ff1e38;
$watermelon: #fd5284;
$niagara: #099a97;
$bittersweet: #ff7272;
$lavender: #be79df;
$boulder: #747474;
$half-baked: #82c4c3;
$cod-gray: #161616;
$secondary: $electric-violet-2;
$respect-mart: #8cc63f;

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//  Maps
//+========================================================================================

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

// Background and  text Colors
$colors: (
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "teal": $teal,
  "cyan": $cyan,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "primary": $primary,
  "secondary": $secondary,
  "black": $black,
  "white": $white,
  "boulder": $boulder,
  "link-water": $link-water,
  "green": $green,
  "coral": $coral,
  "anakiwaap": $anakiwaap,
  "orange": $orange,
  "java": $java,
  "java-2": $java-2,
  "ice-cold": $ice-cold,
  "purple-heart": $purple-heart,
  "blue-ribbon": $blue-ribbon,
  "blue-ribbon-2": $blue-ribbon-2,
  "blue": $blue,
  "green-2": $green-2,
  "tree-poppy": $tree-poppy,
  "ebony-clay": $ebony-clay,
  "electric-violet": $electric-violet,
  "electric-violet-2": $electric-violet-2,
  "radical-red": $radical-red,
  "golden-tainoi": $golden-tainoi,
  "oxford-blue": $oxford-blue,
  "royal-blue": $royal-blue,
  "victoria": $victoria,
  "dodger-blue": $dodger-blue,
  "caribbean-green": $caribbean-green,
  "gorse": $gorse,
  "yellow": $yellow,
  "yellow-2": $yellow-2,
  "yellow-3": $yellow-3,
  "shark": $shark,
  "torch-red": $torch-red,
  "watermelon": $watermelon,
  "niagara": $niagara,
  "bittersweet": $bittersweet,
  "lavender": $lavender,
  "cod-gray": $cod-gray,
  "half-baked": $half-baked,
  "respect-mart": $respect-mart,
);

// For button color
$btn-colors: (
  "primary": $primary,
  "black": $black,
  "white": $white,
  "readical-red ": $readical-red,
  "torch-red": $torch-red,
  "purple-heart": $purple-heart,
  "electric-violet-2": $electric-violet-2,
  "electric-violet-3": $electric-violet-3,
  "bittersweet": $bittersweet,
  "niagara": $niagara,
  "yellow": $yellow,
  "shark": $shark,
  "link-water": $link-water,
  "egg-blue": $egg-blue,
  "green": $green,
  "respect-mart": $respect-mart,
);

$backgroundColors: (
  "blue": $blue,
  "blue-ribbon": $blue-ribbon,
  "blue-ribbon-2": $blue-ribbon-2,
  "green-2": $green-2,
  "coral": $coral,
  "orange": $orange,
  "shark": $shark,
  "java": $java,
  "victoria": $victoria,
  "java-2": $java-2,
  "purple-heart": $purple-heart,
  "electric-violet": $electric-violet,
  "electric-violet-2": $electric-violet-2,
  "electric-violet-3": $electric-violet-3,
  "dodger-blue": $dodger-blue,
  "caribbean-green": $caribbean-green,
  "gorse": $gorse,
  "yellow": $yellow,
  "yellow-3": $yellow-3,
  "athens-gray": $athens-gray,
  "electric-violet-4": $electric-violet-4,
  "flamingo": $flamingo,
  "egg-blue": $egg-blue,
  "tree-poppy": $tree-poppy,
  "readical-red": $readical-red,
  "radical-red": $radical-red,
  "golden-tainoi": $golden-tainoi,
  "bittersweet": $bittersweet,
  "niagara": $niagara,
  "torch-red": $torch-red,
  "half-baked": $half-baked,
  "transparent": transparent,
  "respect-mart": $respect-mart,
);
$TextColors: (
  "blue-ribbon": $blue-ribbon,
  "white": $white,
  "radical-red": $radical-red,
  "green-2": $green-2,
  "shark": $shark,
  "java": $java,
  "java-2": $java-2,
  "tree-poppy": $tree-poppy,
  "ebony-clay": $ebony-clay,
  "torch-red": $torch-red,
  "readical-red ": $readical-red,
  "victoria": $victoria,
  "dodger-blue": $dodger-blue,
  "caribbean-green": $caribbean-green,
  "electric-violet-2": $electric-violet-2,
  "electric-violet-3": $electric-violet-3,
  "gorse": $gorse,
  "egg-blue": $egg-blue,
  "yellow-2": $yellow-2,
  "yellow-3": $yellow-3,
  "bittersweet": $bittersweet,
  "yellow": $yellow,
  "coral": $coral,
  "niagara": $niagara,
  "lavender": $lavender,
  "royal-blue": $royal-blue,
  "respect-mart": $respect-mart,
);

$opacity-colors: (
  "blue-ribbon": $blue-ribbon,
  "white": $white,
  "primary": $primary,
  "radical-red": $radical-red,
  "green-2": $green-2,
  "java-2": $java-2,
  "tree-poppy": $tree-poppy,
  "ebony-clay": $ebony-clay,
  "victoria": $victoria,
  "dodger-blue": $dodger-blue,
  "caribbean-green": $caribbean-green,
  "yellow": $yellow,
  "orange": $orange,
  "blue-ribbon-2": $blue-ribbon-2,
  "electric-violet-": $electric-violet-3,
  "torch-red": $torch-red,
);

// $body-bg        : var(--bg);
$body-color: var(--color-texts);
// $border-color   : var(--border-color);
$headings-color: var(--color-headings);

// Create your own map
$custom-colors: (
  "custom-color": #900,
);

// Merge the maps
$theme-colors: (map-merge($theme-colors, $custom-colors));
